import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
import { IPackage } from "@/models/IPackage";
import ClientService from "@/services/ClientService";
import { IClientSave } from "@/models/IClientSave";
import { IClientSettings } from "@/models/IClientSettings";
import Multiselect from "vue-multiselect";
import { TheMask } from "vue-the-mask";

@Options({
  components: {
    AdminLayout,
    Multiselect,
    TheMask,
  },
})
export default class AddClient extends Vue {
  package: IPackage[] = [];
  submitted: boolean = false;
  required: boolean = true;
  loading = false;
  clientId = "";
  userId = 0;
  selectedPackage: any = {};

  client: IClientSave = {
    clientId: 0,
    clientName: "",
    address: "",
    cityId: 0,
    phone: "",
    email: "",
    packageId: 0,
    isActive: false,
    userId: 0,
  };

  settings: IClientSettings = {
    clientSettingId: 0,
    clientId: 0,
    smsapi: "",
    smssecret: "",
    userId: 0,
    UserKey: "",
    AccessKey: "",
  };

  async created() {
    this.package = await ClientService.getAllPackage();
    this.clientId = this.$route.query.clientId.toString();
    this.client = await ClientService.getClienByClientId(
      parseInt(this.clientId)
    );
    console.log(this.client);
    var user = JSON.parse(localStorage.getItem("user") || "{}");
    this.userId = user.userId;
    this.client.userId = this.userId;

    this.selectedPackage = this.package.find(
      (option) => option.packageId === this.client.packageId
    );
  }

  onBack() {
    this.$router.push("/admin/clients");
  }

  onSubmit() {
    (this as any).$Progress.start();
    this.client.packageId = this.selectedPackage.packageId;
    this.loading = true;
    ClientService.saveClient(this.client).then(
      (data) => {
        this.loading = false;
        console.log(data);
        (this as any).$vs.notify({
          title: "New Client",
          text: data.message,
          color: "success",
          icon: "check_circle",
        });
        (this as any).$Progress.finish();
        if (this.client.clientId == 0) {
          this.client = {
            clientId: 0,
            clientName: "",
            address: "",
            cityId: 0,
            phone: "",
            email: "",
            packageId: 0,
            isActive: false,
            userId: this.userId,
          };
        }
      },
      (error) => {
        this.loading = false;
        (this as any).$Progress.finish();
        (this as any).$vs.notify({
          title: "New Client",
          text: error,
          color: "danger",
          icon: " highlight_off",
        });
      }
    );

    //console.log(response);
  }
}
